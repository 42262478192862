import { gray } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainerInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-top: 5vh;
  margin-bottom: 2.5vh;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

export const TextFullName = styled.p`
  font-size: 3.5vw;
  font-weight: 500;
  text-align: center;
  color: ${gray};
  margin-top: 1.3vh;

  @media ${DEVICES.mobile} {
    font-size: 30px;
  }
`

export const TextInfo = styled.p`
  font-size: 1.4vw;
  color: ${gray};
  margin-top: 20px;
  text-align: center;

  @media ${DEVICES.tablet} {
    font-size: 16px;
  }

  @media ${DEVICES.mobile} {
    font-size: 20px;
    margin-bottom: 20px;
  }
`

export const ViewInformation = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 32%;

  @media ${DEVICES.mobile} {
    width: 80%;
  }
`
export const TextIconView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const SVGTouchableOpacity = styled.div`
  position: absolute;
  margin-top: 20px;
  right: 0;
  cursor: pointer;
`

export const SVGTouchableCopyAlias = styled.div`
  position: absolute;
  margin-top: 20px;
  right: 30px;
  cursor: pointer;
`
