import { MenuState } from './types'

const initialState: MenuState = {
  vendor: {
    name: '',
    lastName: '',
    phone: '',
    email: ''
  },
  isLoading: false,
  typepaymentmethod: [],
  paymentmethod: [],
  redeemers: [],
  countPageRedeemers: 1,
  multiEnterpriseAssociation: []
}

export default initialState
