import React, { useState } from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import { ContractDetailList } from 'components'
import { Drawers } from 'store/drawer/types'
import { showDrawer, hideDrawer } from 'store/drawer/actions'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ButtonsContainer,
  Button,
  ContentContainer,
  ModalContent,
  ModalButtonsContainer,
  ModalButton,
  TextContainer
} from './styles'
import { SaleOrderStatus } from 'store/contracts/types'
import { replyCounterOffer } from 'store/contracts/thunks'
import { useDispatch } from 'react-redux'

import CustomModal from 'components/organisms/CustomModal'
import wrapper from 'components/organisms/Drawers/wrapper'
import { Text } from '@chakra-ui/react'

interface Modal {
  onClose: () => void;
  isOpen: boolean;
}

const ModalConfirmation: React.FC<Modal> = ({ onClose, isOpen }) => {
  const dispatch = useDispatch()
  const { selected, isLoading } = useAppSelector((state) => state.contractsV2)

  const onSubmit = async () => {
    try {
      await dispatch(replyCounterOffer(selected?.id as number, true))
      dispatch(showDrawer(Drawers.SALE_ORDER_SUCCESS))
    } catch (error) {
      dispatch(showDrawer(Drawers.SALE_ORDER_ERROR))
    } finally {
      onClose()
      dispatch(hideDrawer(Drawers.SALE_ORDER_DETAILS))
    }
  }

  return (
    <CustomModal title='Contra Oferta' isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <p>Te enviamos una contraoferta a tu orden.</p>
        <p>¿Como querés seguir?</p>
      </ModalContent>
      <ModalButtonsContainer>
        <ModalButton onPress={onSubmit} loading={isLoading}>
          Aceptar
        </ModalButton>
        <ModalButton onPress={onClose} secondary disabled={isLoading}>
          Cerrar
        </ModalButton>
      </ModalButtonsContainer>
    </CustomModal>
  )
}

const ModalCancelation: React.FC<Modal> = ({ onClose, isOpen }) => {
  const dispatch = useDispatch()
  const { selected, isLoading } = useAppSelector((state) => state.contractsV2)

  const onSubmit = async () => {
    try {
      await dispatch(replyCounterOffer(selected?.id as number, false))
      dispatch(showDrawer(Drawers.SALE_ORDER_SUCCESS))
    } catch (error) {
      dispatch(showDrawer(Drawers.SALE_ORDER_ERROR))
    } finally {
      onClose()
      dispatch(hideDrawer(Drawers.SALE_ORDER_DETAILS))
    }
  }

  return (
    <CustomModal title='Contra Oferta' isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <p>Te enviamos una contraoferta a tu orden pero la querés rechazar.</p>
        <p>¿Como querés seguir?</p>
      </ModalContent>
      <ModalButtonsContainer>
        <ModalButton onPress={onSubmit} loading={isLoading}>
          Rechazar
        </ModalButton>
        <ModalButton onPress={onClose} secondary disabled={isLoading}>
          Cerrar
        </ModalButton>
      </ModalButtonsContainer>
    </CustomModal>
  )
}

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const SaleOrderDetails: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { selected } = useAppSelector((state) => state.contractsV2)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [showModalCancelation, setShowModalCancelation] = useState(false)
  const { isRepresentative } = useAppSelector((state) => state.representation)

  const isCounterOffer = selected?.status === SaleOrderStatus.COUNTER_OFFER

  const renderButtons = () => {
    if (isCounterOffer) {
      if (
        (isRepresentative && selected.Representation?.active) ||
        // If you are a representative and the representation is still active
        (!isRepresentative && !selected.Representation?.active)
        // If you are not a representative and the representation is not active
      ) {
        return (
          <ButtonsContainer>
            <Button onPress={() => setShowModalCancelation(true)} secondary>
              Rechazar
            </Button>
            <Button onPress={() => setShowModalConfirmation(true)}>
              Aceptar
            </Button>
          </ButtonsContainer>
        )
      } else {
        return (
          <TextContainer>
            <Text align='center'>
              Este contrato fue realizado por tu representante y no podés editarlo
            </Text>
          </TextContainer>
        )
      }
    }
    return (
      <ButtonsContainer>
        <Button onPress={onClose}>
          Entendido
        </Button>
      </ButtonsContainer>
    )
  }

  return (
    <>
      <ModalConfirmation
        isOpen={showModalConfirmation}
        onClose={() => setShowModalConfirmation(false)}
      />
      <ModalCancelation
        isOpen={showModalCancelation}
        onClose={() => setShowModalCancelation(false)}
      />
      <Drawer
        open={showDrawer}
        onClose={onClose}
        direction="right"
      >
        <DrawerContainer>
          <HeaderContainer>
            <BackButton onPress={onClose}>
              <BackArrowIcon />
            </BackButton>
            <Title>Términos de la orden</Title>
          </HeaderContainer>
          <ContentContainer>
            <ContractDetailList saleOrder={selected} />
          </ContentContainer>
          {renderButtons()}
        </DrawerContainer>
      </Drawer>
    </>

  )
}

export default wrapper(SaleOrderDetails, Drawers.SALE_ORDER_DETAILS)
