import { RepresentationState } from './types'

const initialState: RepresentationState = {
  represented: [],
  isLoading: false,
  isLoadingRepresentative: false,
  isDeletingRepresentative: false,
  isRepresentativeLoading: false,
  error: undefined,
  selectedRepresented: undefined,
  isRepresentative: false,
  representative: undefined,
  dataRepresentativeProvisory: undefined
}

export default initialState
