import { instance } from '.'
import { insertKeyEndpoint } from './utils'
import { IsRepresentative, Representative, Represented } from 'store/representation/types'

interface Response<T> {
  data: T;
  error: boolean;
  message: string;
}

export const getRepresented = async (id: number) => {
  return instance.get<Response<Represented[]>>(insertKeyEndpoint(`representation/represented/${id}`))
}

export const getIsRepresentative = async () => {
  return instance.get<Response<IsRepresentative>>(insertKeyEndpoint('representation/is-representative'))
}

export const getRepresentative = async () => {
  return instance.get<Response<Representative>>(insertKeyEndpoint('representation/representative'))
}

export const getRepresentativeCompanyData = async (taxId: string) => {
  return instance.get<Representative>(insertKeyEndpoint(`company/cuit/${taxId}`))
}

export const setRepresentative = async (representativeId: number) => {
  return instance.post<Response<Representative>>(insertKeyEndpoint('representation/create'), { representativeId })
}

export const deleteRepresentative = async () => {
  return instance.put(insertKeyEndpoint('representation/deactivate'))
}
