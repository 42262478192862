import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { Box } from '@chakra-ui/react'
import FormDelivery, {
  formDeliveryInitialValues,
  formDeliveryValidator,
  FormDeliveryValues
} from 'components/organisms/OperationFormSteps/FormDelivery'
import FormGrain, {
  formGrainInitialValues,
  formGrainValidator,
  FormGrainValues
} from 'components/organisms/OperationFormSteps/FormGrain'
import StepperContract from 'components/molecules/StepperContract'
import { getConditionType } from '@qirapagos/web/src/store/contracts/thunks'
import { AppOperation } from '@qirapagos/web/src/store/contracts/types'
import { merge } from '@qirapagos/web/src/validations/operationsForms'
import { showDrawer } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { parseDate } from 'utils/common/index'
import { Container } from './styles'

type FormValues = FormDeliveryValues & FormGrainValues & { appOperation: AppOperation }

const SalePriceFixed: React.FC = () => {
  const dispatch = useDispatch()

  const [currentStepBox, setCurrentStepBox] = useState(0)
  const [isFirstComplete, setFirstIsComplete] = useState(false)
  const [isSecondComplete, setSecondIsComplete] = useState(false)

  useEffect(() => {
    dispatch(getConditionType())
  }, [dispatch])

  const titlesInfo = [
    {
      title: '1. Condición de granos',
      isComplete: isFirstComplete
    },
    {
      title: '2. Procedencia',
      isComplete: isSecondComplete
    }
  ]

  const onSubmit = (values: FormValues) => {
    const saleOrder = {
      appOperation: values.appOperation,
      // FormGrain
      sellerTaxId: values.sellerTaxId,
      currencyId: values.currencyId,
      grainId: values.grainId,
      harvestDateId: values.harvestDateId,
      price: values.price,
      tons: values.tons,
      // FormDelivery
      originId: values.originId,
      destinationId: values.destinationId,
      deliveryPeriod: values.deliveryPeriod,
      deliveryFrom: parseDate(values.deliveryFrom, 'yyyy-MM-dd'),
      deliveryTo: parseDate(values.deliveryTo, 'yyyy-MM-dd')
    }
    dispatch(showDrawer(Drawers.CONFIRM_NEW_SALE_ORDER, { saleOrder }))
  }

  const formik = useFormik({
    initialValues: {
      appOperation: AppOperation.SALE_FIXED,
      ...formDeliveryInitialValues,
      ...formGrainInitialValues
    },
    validationSchema: merge(
      formGrainValidator,
      formDeliveryValidator
    ),
    onSubmit
  })

  useEffect(() => {
    const {
      sellerTaxId, currencyId, grainId, harvestDateId, price, tons,
      stateOrigin, originId, stateDestination, destinationId, deliveryPeriod, deliveryFrom, deliveryTo
    } = formik.values
    // FormGrain
    if (sellerTaxId && currencyId && grainId && harvestDateId && price && tons) {
      setFirstIsComplete(true)
      setCurrentStepBox(1)
    } else {
      setFirstIsComplete(false)
      setCurrentStepBox(0)
    }
    // FormDelivery
    if (stateOrigin && originId && stateDestination && destinationId && deliveryPeriod && deliveryFrom && deliveryTo) {
      setSecondIsComplete(true)
    } else {
      setSecondIsComplete(false)
    }
  }, [formik.values])

  return (
    <>
      <StepperContract
        titlesInfo={titlesInfo}
        currentStepBox={currentStepBox}
      />
      <Container>
        <Helmet title="Nueva orden de venta" />
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <FormGrain
              titlesInfo="1. Condición de granos"
              formik={formik}
            />
          </Box>
          <Box>
            <FormDelivery
              titlesInfo="2. Entrega"
              formik={formik}
            />
          </Box>
        </form>
      </Container>
    </>
  )
}

export default SalePriceFixed
