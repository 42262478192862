import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table, { TABLE } from 'components/organisms/TableV2'
import useNavigation from 'hooks/useNavigation'
import GrainDesktopSkeleton from 'assets/skeletons/GrainDesktopSkeleton'
import { getSaleOrders } from 'store/contracts/thunks'
import { selectSaleOrder } from 'store/contracts/actions'
import {
  appOperationText,
  AppOperation,
  SaleOrderStatus
} from 'store/contracts/types'
import { showDrawer } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { Box, Menu, MenuItem, Text } from '@chakra-ui/react'
import { WHATSAPP_LINK } from '@qirapagos/lib/constants/common'
import Helmet from 'components/utilities/Helmet'
import Status from 'components/atoms/Status'
import {
  parseDate,
  getCurrencySymbol,
  SaleOrderCurrency,
  isInIntervalAndWeekday
} from 'utils/common'
import { getSaleOrderStatus } from 'utils/operations'
import { SCREEN_TITLES } from 'utils/navigation'
import { purple, violet } from '@qirapagos/lib/theme/colors'
import {
  Container,
  Header,
  Title,
  Operations,
  Operation,
  OperationTitle,
  DollarIcon,
  HandshakeIcon,
  WhatsAppIcon,
  MenuList,
  StatusContainer,
  OperationButton,
  ButtonContainer,
  ModalDescription,
  ModalButton
} from './styles'
import { RootState } from 'store'
import { CustomModal } from 'components'
import { ReactComponent as AlzLogoBig } from 'assets/svg/AlzLogoBig.svg'
import { getIsRepresentative } from 'store/representation/thunks'

export const OPERATION_TYPES = {
  TRADE: 'TRADE',
  SALE: 'SALE',
  OTHER: 'OTHER'
}

const Home: React.FC = () => {
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()
  const isOutOfTimeRange = !isInIntervalAndWeekday()
  const [isOpen, setIsOpen] = useState(false)

  const [visible, setVisible] = useState({
    saleModal: false,
    tradeModal: false,
    contactModal: false
  })

  const { isLoading, saleOrders } = useSelector(
    (state: RootState) => state.contractsV2
  )
  const { multiEnterprise } = useSelector((state: RootState) => state.auth)
  const { isRepresentative } = useSelector((state: RootState) => state.representation)

  useEffect(() => {
    dispatch(getIsRepresentative())
  }, [dispatch])

  const hasExtranetAccess = multiEnterprise[0]?.url !== null

  const SALE_ACTIONS = [
    {
      businessName: 'con precio hecho',
      callback: () =>
        onNavigate('/content/grains/operation-form/sale-price-fixed', {
          title: 'Nueva orden de venta con precio hecho'
        })
    },
    {
      businessName: 'con precio a fijar',
      callback: () =>
        onNavigate('/content/grains/operation-form/sale-price-to-set', {
          title: 'Nueva orden de venta con precio a fijar'
        })
    }
  ]

  const TRADE_ACTIONS = [
    {
      businessName: 'con precio hecho',
      callback: () =>
        onNavigate('/content/grains/operation-form/trade-price-fixed', {
          title: 'Nueva orden de canje con precio hecho'
        })
    },
    {
      businessName: 'con precio a fijar',
      callback: () =>
        onNavigate('/content/grains/operation-form/trade-price-to-set', {
          title: 'Nueva orden de canje con precio a fijar'
        })
    }
  ]

  useEffect(() => {
    dispatch(getSaleOrders(!!saleOrders.length))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const onPressOperations = (text: string) => {
    if (isOutOfTimeRange) {
      return dispatch(showDrawer(Drawers.OUT_OF_TIME))
    }
    switch (text) {
      case OPERATION_TYPES.SALE:
        setVisible({
          ...visible,
          saleModal: !visible.saleModal,
          tradeModal: false,
          contactModal: false
        })
        break
      case OPERATION_TYPES.TRADE:
        setVisible({
          ...visible,
          tradeModal: !visible.tradeModal,
          saleModal: false,
          contactModal: false
        })

        break
      case OPERATION_TYPES.OTHER:
        window.open(WHATSAPP_LINK, '_blank')
        break
      default:
        break
    }
  }

  if (isLoading) {
    return <GrainDesktopSkeleton />
  }

  return (
    <Container>
      <Helmet title={SCREEN_TITLES.GRAINS} />
      <Header>
        <Title>Nueva operación</Title>
      </Header>
      <Operations>
        <Menu placement="bottom-end">
          <Operation onClick={() => onPressOperations('SALE')}>
            <DollarIcon />
            <OperationTitle>Venta</OperationTitle>
            <OperationButton visible={visible.saleModal} color={violet} />
          </Operation>
          <MenuList zIndex={100} disabled={isOutOfTimeRange}>
            <MenuItem
              _hover={{ color: purple, bg: 'graySoft' }}
              onClick={SALE_ACTIONS[0].callback}
            >
              <Box w="100%" textAlign="end" id="sell-fixed-price-btn">
                <Text
                  w="100%"
                  fontFamily="Poppins"
                  fontSize="15px"
                  fontWeight={500}
                >
                  con precio hecho
                </Text>
              </Box>
            </MenuItem>
            <MenuItem
              _hover={{ color: purple, bg: 'graySoft' }}
              onClick={SALE_ACTIONS[1].callback}
            >
              <Box w="100%" textAlign="end" id="sell-price-to-fix-btn">
                <Text
                  w="100%"
                  fontFamily="Poppins"
                  fontSize="15px"
                  fontWeight={500}
                >
                  con precio a fijar
                </Text>
              </Box>
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu placement="bottom-end">
          <Operation onClick={() => onPressOperations('TRADE')}>
            <HandshakeIcon />
            <OperationTitle>Canje</OperationTitle>
            <OperationButton visible={visible.tradeModal} color={violet} />
          </Operation>
          <MenuList zIndex={100} disabled={isOutOfTimeRange}>
            <MenuItem
              _hover={{ color: purple, bg: 'graySoft' }}
              onClick={TRADE_ACTIONS[0].callback}
            >
              <Box w="100%" textAlign="end" id="trade-fixed-price-btn">
                <Text
                  w="100%"
                  fontFamily="Poppins"
                  fontSize="15px"
                  fontWeight={500}
                >
                  con precio hecho
                </Text>
              </Box>
            </MenuItem>
            <MenuItem
              _hover={{ color: purple, bg: 'graySoft' }}
              onClick={TRADE_ACTIONS[1].callback}
            >
              <Box w="100%" textAlign="end" id="trade-price-to-fix-btn">
                <Text
                  w="100%"
                  fontFamily="Poppins"
                  fontSize="15px"
                  fontWeight={500}
                >
                  con precio a fijar
                </Text>
              </Box>
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu placement="bottom-end">
          <Operation onClick={() => onPressOperations('OTHER')}>
            <WhatsAppIcon />
            <OperationTitle>Otras operaciones</OperationTitle>
          </Operation>
        </Menu>
        <Menu placement="bottom-end">
          <Operation
            disabled={!hasExtranetAccess}
            onClick={() => setIsOpen(true)}
          >
            <AlzLogoBig />
            <OperationTitle>Clientes ALZ AGRO</OperationTitle>
          </Operation>
        </Menu>
      </Operations>
      <Table
        data={saleOrders}
        title="Historial de contratos"
        config={[
          {
            id: 'id',
            header: '#',
            meta: {
              formatter: (value: number) => {
                return value?.toString().padStart(4, '0')
              }
            }
          },
          {
            id: 'appOperation',
            header: 'Tipo de Contrato',
            meta: {
              formatter: (value: AppOperation) => {
                return appOperationText[value]
              }
            }
          },
          {
            id: 'createdAt',
            header: 'Fecha',
            meta: {
              formatter: (value: Date) => {
                return parseDate(value)
              }
            }
          },
          {
            id: 'currencyId',
            header: 'Moneda',
            meta: {
              formatter: (value: SaleOrderCurrency) => {
                return getCurrencySymbol(value)
              }
            }
          },
          { id: 'price', header: 'Precio' },
          { id: 'tons', header: 'Toneladas' },
          {
            id: 'status',
            header: 'Estado',
            meta: {
              // eslint-disable-next-line react/no-unstable-nested-components
              formatter: (value: SaleOrderStatus) => {
                return (
                  <StatusContainer>
                    <Status statusType={getSaleOrderStatus(value)} />
                  </StatusContainer>
                )
              }
            }
          },
          {
            id: isRepresentative
              ? 'Representation.represented.companyName'
              : 'Representation.representative.companyName',
            header: isRepresentative ? 'A nombre de' : 'Creada por',
            meta: {
              formatter: (value: string) => {
                return value || 'Mi cuenta'
              }
            }
          },
          {
            id: TABLE.ACTION,
            header: '',
            meta: {
              actions: ({ row }: any) => [
                {
                  title: 'Ver detalle',
                  onPress: () => {
                    dispatch(selectSaleOrder(row.original))
                    dispatch(showDrawer(Drawers.SALE_ORDER_DETAILS))
                  }
                },
                {
                  title: 'Ver actividad',
                  onPress: () => {
                    onNavigate(`/content/grains/activity/${row.original.id}`, {
                      title: 'Detalle de tu actividad',
                      hasBackArrow: true
                    })
                  }
                }
              ]
            }
          }
        ]}
        isLoading={isLoading}
        emptyText="Aún no tenés ningún contrato"
      />
      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Saliendo de Qira"
      >
        <ModalDescription>
          <Text>Serás redireccionado a la sección</Text>
          <Text>de Clientes de ALZ AGRO</Text>
        </ModalDescription>
        <ButtonContainer>
          <ModalButton
            onPress={() => {
              setIsOpen(false)
              window.open(multiEnterprise[0]?.url, '_blank')
            }}
          >
            Continuar
          </ModalButton>
          <ModalButton secondary onPress={() => setIsOpen(false)}>
            Cancelar
          </ModalButton>
        </ButtonContainer>
      </CustomModal>
    </Container>
  )
}

export default Home
