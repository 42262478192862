import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import { CustomList, AvatarOutline } from 'components'
import { Drawers } from 'store/drawer/types'
import {
  Drawer,
  Title,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ButtonsContainer,
  Button,
  ContentContainer,
  AvatarContainer,
  InfoContainer,
  customListStyles
} from './styles'
import wrapper from 'components/organisms/Drawers/wrapper'
import { getInitials } from '@qirapagos/lib/utils/common'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const DrawerRepresentedDetail: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { selectedRepresented } = useAppSelector((state) => state.representation)

  const REPRESENTED_INFO = [
    { NOMBRE: selectedRepresented?.companyName || 'SIN DATOS' },
    { CUIT: selectedRepresented?.taxId || 'SIN DATOS' },
    { EMAIL: selectedRepresented?.email || 'SIN DATOS' },
    { TELÉFONO: selectedRepresented?.phone || 'SIN DATOS' },
    { VENDEDOR: selectedRepresented?.vendor || 'SIN DATOS' },
    { ESTADO: selectedRepresented?.status || 'SIN DATOS' }
  ]

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onPress={onClose}>
            <BackArrowIcon />
          </BackButton>
          <Title>Detalle</Title>
        </HeaderContainer>
        <ContentContainer>
          <AvatarContainer>
            <AvatarOutline isBig initials={getInitials(selectedRepresented?.companyName || '')} />
          </AvatarContainer>
          <InfoContainer>
            <CustomList
              data={REPRESENTED_INFO}
              positioning="vertical"
              customStyle={customListStyles}
            />
          </InfoContainer>
        </ContentContainer>
        <ButtonsContainer>
          <Button onPress={onClose}>
            Entendido
          </Button>
        </ButtonsContainer>
      </DrawerContainer>
    </Drawer>

  )
}

export default wrapper(DrawerRepresentedDetail, Drawers.REPRESENTED_DETAIL)
