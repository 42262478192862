import * as Sentry from '@sentry/react'
import { ThunkDispatchType } from '@qirapagos/lib/interfaces/reduxInterfaces'
import * as MenuActions from '@qirapagos/lib/store/menu/actions'
import { MultiEnterpriseServices, MenuServices } from 'services'
import { getToken } from 'services/utils'
import { setMultiEnterpriseAssociations } from '@qirapagos/lib/store/menu/actions'
import { ErrorMessages } from '@qirapagos/lib/utils/common'

export const getMultiEnterpriseAssociations =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(MenuActions.setMenuLoading())
      const token = await getToken()
      const loginId = await localStorage.getItem('loginId')
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const response: any = await MultiEnterpriseServices.getAssociations(
        loginId as string,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          loginId
        }
      })
      if (response.status === 400) {
        dispatch(setMultiEnterpriseAssociations([]))
        dispatch(MenuActions.dismissMenuLoading())
        return
      }
      dispatch(setMultiEnterpriseAssociations(response.data))
      dispatch(MenuActions.dismissMenuLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir mis asociaciones:${error}`,
        'warning'
      )
      dispatch(setMultiEnterpriseAssociations([]))
      dispatch(MenuActions.dismissMenuLoading())
    }
  }

export const getRedeemers =
    (page = 1, limit = 5) =>
      async (dispatch: ThunkDispatchType) => {
        try {
          dispatch(MenuActions.setMenuLoading())
          const token = await getToken()
          const keyEndpoints = await localStorage.getItem('keyendpoints')
          const response: any = await MenuServices.getRedeemers(
            limit,
            page,
            token as string,
            keyEndpoints as string
          )
          Sentry.setExtras({
            data: {
              response: response.data,
              token
            }
          })
          if (response.status !== 200 && response?.data?.error_message) {
            throw ErrorMessages.DEFAULT_REQUEST_ERROR
          }
          dispatch(MenuActions.setCountPageRedeemers(response.data.countPages))
          dispatch(MenuActions.setRedeemers(response.data.redeemingCompany))
          dispatch(MenuActions.dismissMenuLoading())
        } catch (error) {
          Sentry.captureMessage(
          `Error al conseguir representantes:${error}`,
          'warning'
          )
          dispatch(MenuActions.dismissMenuLoading())
          dispatch(MenuActions.setClearRedeemers())
        }
      }
