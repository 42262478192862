import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'

const REDEEMERS = 'redeemingCompanies'

export const getRedeemers = (
  limit: number,
  page: number,
  token: string,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${REDEEMERS}?limit=${limit}&page=${page}`, keyEndpoints),
    {},
    { headers: { token } }
  )
