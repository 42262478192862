import { createStore, applyMiddleware, combineReducers, AnyAction } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import auth from '@qirapagos/lib/store/auth/reducer'
import authV2 from 'store/auth/reducer'
import activity from 'store/activity/reducer'
import transfers from '@qirapagos/lib/store/transfers/reducer'
import contracts from '@qirapagos/lib/store/contracts/reducer'
import contractsV2 from 'store/contracts/reducer'
import menu from '@qirapagos/lib/store/menu/reducer'
import modal from '@qirapagos/lib/store/modals/reducer'
import signature from '@qirapagos/web/src/store/signature/reducer'
import drawer from '@qirapagos/web/src/store/drawer/reducer'
import notifications from 'store/notifications/reducer'
import account from 'store/account/reducer'
import location from 'store/location/reducer'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import representation from 'store/representation/reducer'

const reducers = combineReducers({
  auth,
  authV2,
  activity,
  transfers,
  contracts,
  contractsV2,
  menu,
  notifications,
  modal,
  signature,
  account,
  drawer,
  location,
  representation
})

const enhancers = []

enhancers.push(applyMiddleware(thunk))

const store = createStore(reducers, composeWithDevTools(...enhancers))

export type RootState = ReturnType<typeof reducers>

export type ThunkDispatchType = ThunkDispatch<RootState, unknown, AnyAction>;

export default store
