import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { HomeLayout } from 'components'
import { TableProvider } from 'contexts/TableContext'
import {
  ActivityGrains,
  ChangePasswordMydata,
  ChangePhoneMyData,
  Unsubscribe,
  CustomerSupport,
  Documentation,
  Grains,
  Redeemers,
  MyData,
  Wallet,
  YourAccount,
  AccountDetails,
  MyEnterprises,
  Quotas,
  Fixings,
  NewTransfer,
  MyContacts,
  TransferSteps,
  AddContact,
  EditObservation,
  DeleteContact,
  ChangeEmailMydata,
  TransferLimit,
  BlockAccount,
  Signature,
  ChangeAlias
} from 'screens'
import NewSaleOrderPriceFixed from 'screens/Home/Grains/OperationForms/NewSaleOrderPriceFixed'
import NewSaleOrderPriceToFix from 'screens/Home/Grains/OperationForms/NewSaleOrderPriceToFix'
import NewSaleOrderSwapFixed from 'screens/Home/Grains/OperationForms/NewSaleOrderSwapFixed'
import NewSaleOrderSwapToFix from 'screens/Home/Grains/OperationForms/NewSaleOrderSwapToFix'
import { useTimeOutLogout } from 'hooks/useTimeOutLogout'
import SisaAndTimeCheck from './SisaAndTimeCheck'
import ChangeAddressMyData from 'screens/Home/MyData/ChangeAddress'
import { updateDeviceId } from 'store/auth/thunks'
import { useDispatch } from 'react-redux'
import Representation from 'screens/Menu/Representation'

const ContentRoutes = () => {
  const dispatch = useDispatch()

  useTimeOutLogout()

  useEffect(() => {
    dispatch(updateDeviceId())
  }, [dispatch])

  return (
    <TableProvider>
      <HomeLayout>
        <Routes>
          <Route path="wallet" element={<Wallet />} />
          <Route path="grains" element={<Grains />} />
          <Route path="grains/activity/:saleOrderId" element={<ActivityGrains />} />
          <Route path="your-account" element={<YourAccount />} />
          <Route path="your-account/details" element={<AccountDetails />} />
          <Route path="your-account/limit-transfer" element={<TransferLimit />} />
          <Route path="your-account/new-transfer" element={<NewTransfer />} />
          <Route path="your-account/my-contacts" element={<MyContacts />} />
          <Route
            path="your-account/my-contacts/add-contact"
            element={<AddContact />}
          />
          <Route
            path="your-account/my-contacts/edit-contact"
            element={<EditObservation />}
          />
          <Route
            path="your-account/my-contacts/delete-contact"
            element={<DeleteContact />}
          />
          <Route
            path="your-account/new-transfer/transfer-steps"
            element={<TransferSteps />}
          />
          <Route
            path="grains/operation-form/trade-price-to-set"
            element={(
              <SisaAndTimeCheck>
                <NewSaleOrderSwapToFix />
              </SisaAndTimeCheck>
            )}
          />
          <Route
            path="grains/operation-form/sale-price-to-set"
            element={(
              <SisaAndTimeCheck>
                <NewSaleOrderPriceToFix />
              </SisaAndTimeCheck>
            )}
          />
          <Route
            path="grains/operation-form/trade-price-fixed"
            element={(
              <SisaAndTimeCheck>
                <NewSaleOrderSwapFixed />
              </SisaAndTimeCheck>
            )}
          />
          <Route
            path="grains/operation-form/sale-price-fixed"
            element={(
              <SisaAndTimeCheck>
                <NewSaleOrderPriceFixed />
              </SisaAndTimeCheck>
            )}
          />
          <Route path="grains/quotas-set/:saleOrderId" element={<Quotas />} />
          <Route path="grains/fixings" element={<Fixings />} />
          <Route path="my-data" element={<MyData />} />
          <Route
            path="my-data/change-password"
            element={<ChangePasswordMydata />}
          />
          <Route
            path="my-data/unsubscribe"
            element={<Unsubscribe />}
          />
          <Route
            path="my-data/change-email"
            element={<ChangeEmailMydata />}
          />
          <Route
            path="my-data/change-phone"
            element={<ChangePhoneMyData />}
          />
          <Route
            path="my-data/change-address"
            element={<ChangeAddressMyData />}
          />
          <Route
            path="your-account/details/change-alias"
            element={<ChangeAlias />}
          />
          <Route path="customer-support" element={<CustomerSupport />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="documentation/signature" element={<Signature />} />
          <Route path="your-enterprises" element={<MyEnterprises />} />
          <Route path="block-account" element={<BlockAccount />} />
          <Route path="redeemers" element={<Redeemers />} />
          <Route path="representation" element={<Representation />} />
        </Routes>
      </HomeLayout>
    </TableProvider>
  )
}

export default ContentRoutes
