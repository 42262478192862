import { AnyAction } from 'redux'
import initialState from './initialState'
import { RepresentationState, RepresentationActionTypes } from './types'

export default function representationReducer (state = initialState, action: AnyAction): RepresentationState {
  switch (action.type) {
    case RepresentationActionTypes.GET_REPRESENTED_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case RepresentationActionTypes.GET_REPRESENTED_FULFILLED:
      return {
        ...state,
        represented: action.payload,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.GET_REPRESENTED_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.SET_REPRESENTED_SELECTED:
      return {
        ...state,
        selectedRepresented: action.payload
      }
    case RepresentationActionTypes.GET_IS_REPRESENTATIVE_PENDING:
      return {
        ...state,
        error: initialState.error,
        isRepresentativeLoading: true
      }
    case RepresentationActionTypes.GET_IS_REPRESENTATIVE_FULFILLED:
      return {
        ...state,
        isRepresentative: action.payload.isRepresentative,
        isRepresentativeLoading: initialState.isLoading
      }
    case RepresentationActionTypes.GET_IS_REPRESENTATIVE_REJECTED:
      return {
        ...state,
        error: action.payload,
        isRepresentativeLoading: initialState.isLoading
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoadingRepresentative: true
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_FULFILLED:
      return {
        ...state,
        representative: action.payload,
        isLoadingRepresentative: initialState.isLoadingRepresentative
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoadingRepresentative: initialState.isLoadingRepresentative
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_FULFILLED:
      return {
        ...state,
        dataRepresentativeProvisory: action.payload,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.CLEAR_REPRESENTATIVE_COMPANY_DATA_REJECTED:
      return {
        ...state,
        dataRepresentativeProvisory: initialState.dataRepresentativeProvisory
      }
    case RepresentationActionTypes.SET_REPRESENTATIVE_PENDING:
      return {
        ...state,
        error: initialState.error,
        isLoading: true
      }
    case RepresentationActionTypes.SET_REPRESENTATIVE_FULFILLED:
      return {
        ...state,
        representative: action.payload,
        dataRepresentativeProvisory: initialState.dataRepresentativeProvisory,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.SET_REPRESENTATIVE_REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: initialState.isLoading
      }
    case RepresentationActionTypes.DELETE_REPRESENTATIVE_PENDING:
      return {
        ...state,
        error: initialState.error,
        isDeletingRepresentative: true
      }
    case RepresentationActionTypes.DELETE_REPRESENTATIVE_FULFILLED:
      return {
        ...state,
        representative: initialState.representative,
        isDeletingRepresentative: initialState.isDeletingRepresentative
      }
    case RepresentationActionTypes.DELETE_REPRESENTATIVE_REJECTED:
      return {
        ...state,
        error: action.payload,
        isDeletingRepresentative: initialState.isDeletingRepresentative
      }
    default:
      return state
  }
}
