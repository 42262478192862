/* eslint-disable no-undef */
import React from 'react'
import { CustomModal } from 'components'
import CustomText from 'components/atoms/CustomText'
import Button from 'components/atoms/Button'
import YourSellerItem from 'assets/svg/YourSellerItem'
import { useDispatch } from 'react-redux'
import useNavigation from 'hooks/useNavigation'
import { white } from '@qirapagos/lib/theme/colors'
import { BoxContentContainer, BoxButtonsContainer, styles } from './styles'
import { View } from 'react-native'
import { deleteRepresentative } from 'store/representation/thunks'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const AgentModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const onPressDelete = () => {
    dispatch(deleteRepresentative())
    setIsOpen(false)
    onNavigate('/content/representation')
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Eliminar representante"
      icon={<YourSellerItem active strokeColor={white} />}
    >
      <BoxContentContainer>
        <View style={{ width: '100%', marginTop: 15 }}>
          <CustomText size="big">
            {'¿Estás seguro que querés\n eliminar a tu representante?'}
          </CustomText>
        </View>
        <CustomText size="xmedium" color="gray">
          Una vez que elimines a tu representante podrás elegir a otro o
          continuar sin uno.
        </CustomText>
        <BoxButtonsContainer>
          <Button
            disabled={false}
            customStyle={styles.button}
            onPress={onPressDelete}
          >
            Eliminar
          </Button>
          <Button
            disabled={false}
            secondary
            customStyle={styles.button}
            onPress={() => setIsOpen(false)}
          >
            Cancelar
          </Button>
        </BoxButtonsContainer>
      </BoxContentContainer>
    </CustomModal>
  )
}

export default AgentModal
