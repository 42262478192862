import React, { useEffect } from 'react'
import Table, { TABLE } from 'components/organisms/TableV2'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import {
  Container
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { getRepresented } from 'store/representation/thunks'
import { setRepresentedSelected } from 'store/representation/actions'
import { Drawers } from 'store/drawer/types'
import { showDrawer } from 'store/drawer/actions'
import RepresentationSkeleton from 'assets/skeletons/RepresentationSkeleton'

const Represented: React.FC = () => {
  const dispatch = useDispatch()

  const companyId = Number(localStorage.getItem('companyId'))
  const { represented, isLoading } = useSelector((state: RootState) => state.representation)

  useEffect(() => {
    dispatch(getRepresented(companyId))
  }, [dispatch, companyId])

  const representedFormatted = represented?.map((rep) => {
    return {
      id: rep.companyId,
      companyName: rep.represented.companyName,
      taxId: rep.represented.taxId,
      phone: rep.represented.phone,
      email: rep.represented.email,
      vendor: rep.represented.Vendor.name,
      status: rep.represented.CompanyStatus.status
    }
  })

  if (isLoading) {
    return (<RepresentationSkeleton />)
  }

  return (
    <Container>
      <Helmet title={SCREEN_TITLES.REPRESENTED_ACCOUNTS} />
      <Table
        data={representedFormatted || []}
        title="Representados"
        config={[
          {
            id: 'id',
            header: '#',
            meta: {
              formatter: (value: number) => {
                return value?.toString().padStart(4, '0')
              }
            }
          },
          {
            id: 'companyName',
            header: 'Nombre',
            meta: {
              formatter: (value: string) => {
                return value || 'SIN DATOS'
              }
            }
          },
          {
            id: 'taxId',
            header: 'CUIT',
            meta: {
              formatter: (value: string) => {
                return value?.toString() || 'SIN DATOS'
              }
            }
          },
          {
            id: 'phone',
            header: 'Teléfono',
            meta: {
              formatter: (value: string) => {
                return value || 'SIN DATOS'
              }
            }
          },
          {
            id: 'email',
            header: 'Email',
            meta: {
              formatter: (value: string) => {
                return value ? value.substring(0, 12) + '..' : 'SIN DATOS'
              }
            }
          },
          {
            id: 'vendor',
            header: 'Vendedor',
            meta: {
              formatter: (value: string) => {
                return value || 'SIN DATOS'
              }
            }
          },
          {
            id: 'status',
            header: 'Estado',
            meta: {
              formatter: (value: string) => {
                return value || 'SIN DATOS'
              }
            }
          },
          {
            id: TABLE.ACTION,
            header: '',
            meta: {
              actions: (row: any) => [
                {
                  title: 'Ver detalle',
                  onPress: () => {
                    dispatch(setRepresentedSelected(row.row.original))
                    dispatch(showDrawer(Drawers.REPRESENTED_DETAIL))
                  }
                }
              ]
            }
          }
        ]}
        emptyText="Aún no tenés representados"
      />
    </Container>
  )
}

export default Represented
