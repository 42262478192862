export interface IVendor {
  name: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface ITypePaymentMethod {
  paymentMethod: string;
  id: number;
}

export interface IPaymentMethod {
  cbu: string;
  paymentMethod: string;
}
export interface IRedeemer {
  taxid?: string;
  companyname: string;
  address: string;
  addressnumber: string;
  phone: string;
  email: string;
  iibbnumber?: string;
  tdocsicore?: string;
  logo?: string;
  description: string;
  id: number;
  Type: number;
  IDAddressType: number;
  IDLocalidad: number;
  IDIVA?: number;
  IDGanancias?: number;
  IDIngresosBrutos?: number;
  IDRG1394?: number;
  IDVendedor?: number;
  Status: number;
}

export interface IMultiEnterprise {
  businessName: string;
  companyId: number;
  companyStatus: number;
  cuit: string;
  dni: string;
  docs: boolean;
  idclient: number;
  loginId: number;
  onboardingStatus: number;
  sisa: boolean;
  token: string;
  userId: number;
}

export interface MenuState {
  vendor?: IVendor
  isLoading: boolean
  typepaymentmethod?: ITypePaymentMethod[]
  paymentmethod?: IPaymentMethod[]
  redeemers?: IRedeemer[]
  countPageRedeemers?: number
  multiEnterpriseAssociation?: IMultiEnterprise[]
}

export interface MultiEnterpriseAssociation {
  cuit: string;
  businessName: string;
  loginId: number;
  companyStatus?: number;
  companyDescription?: string;
}

export enum MenuActionTypes {
  LOGOUT = '@MENU:LOGOUT',
  SET_LOADING = '@MENU:SET_LOADING',
  DISMISS_LOADING = '@MENU:DISMISS_LOADING',
  SET_VENDOR = '@MENU:SET_VENDOR',
  SET_TYPE_PAYMENT_METHOD = '@MENU:SET_TYPE_PAYMENT_METHOD',
  SET_PAYMENT_METHOD = '@MENU:SET_PAYMENT_METHOD',
  SET_REDEEMERS = '@MENU:SET_REDEEMERS',
  SET_COUNT_PAGE_REDEEMERS = '@MENU:SET_COUNT_PAGE_REDEEMERS',
  SET_CLEAR_REDEEMERS = '@MENU:SET_CLEAR_REDEEMERS',
  SET_MULTIENTERPRISE_ASSOCIATIONS = '@MENU:SET_MULTIENTERPRISE_ASSOCIATIONS',
}
