import styled from 'styled-components/native'

export const ViewIcon = styled.View`
  margin-top: 5vh;
`

export const ViewAvatar = styled.View`
  align-self: center;
`

export const TextInfoTitle = styled.Text``
export const ViewInfoEach = styled.View`
  flex-direction: column;
  align-items: center;
`
export const ButtonTermsContainer = styled.View`
  margin-top: 40;
  margin-bottom: 5;
`

export const style = {
  button: {
    marginBottom: 50,
    height: 60,
    borderRadius: 5
  },
  termAndCon: {
    height: 'fit-content'
  }
}
