import React, { useEffect, useMemo } from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch } from 'react-redux'
import BackArrow from '@qirapagos/web/src/assets/svg/BackArrow'
import { ContractDetailList } from 'components'
import { createSaleOrder } from '@qirapagos/web/src/store/contracts/thunks'
import wrapper from 'components/organisms/Drawers/wrapper'
import { SaleOrderStatus } from 'store/contracts/types'
import { hideDrawer, showDrawer as showDrawerAction } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { useAppSelector } from 'hooks/redux'
import {
  DrawerTitle,
  ButtonContainer,
  getDrawerStyles,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  ContentContainer,
  ConfirmButton,
  ButtonText
} from './styles'
import { getDestinationById, getLocationById } from 'store/location/thunks'
import { getRepresented } from 'store/representation/thunks'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const ConfirmNewSaleOrder: React.FC<Props> = ({ showDrawer, onClose }) => {
  const {
    harvest = [],
    graintype: grainType = []
  } = useAppSelector(state => state.contracts)
  const { isLoading: isCreatingSaleOrder } = useAppSelector(state => state.contractsV2)
  const dispatch = useDispatch()
  const { locations, destinations } = useAppSelector((state) => state.location)
  const { represented } = useAppSelector((state) => state.representation)
  const { data } = useAppSelector(state => state.drawer)
  const { saleOrder } = data[Drawers.CONFIRM_NEW_SALE_ORDER] as any
  const companyId = Number(localStorage.getItem('companyId'))

  useEffect(() => {
    dispatch(getLocationById(saleOrder.originId))
    dispatch(getDestinationById(saleOrder.destinationId))
  }, [dispatch, saleOrder.originId, saleOrder.destinationId])

  useEffect(() => {
    dispatch(getRepresented(companyId))
  }, [dispatch, companyId])

  const locationOrigin = useMemo(() => {
    return locations.map((location) => ({
      title: location.location,
      id: location.id
    }))
  }, [locations])

  const locationDestination = useMemo(() => {
    return destinations.map((destination) => ({
      title: destination.destination,
      id: destination.id
    }))
  }, [destinations])

  const harvestEntity = useMemo(() => {
    return harvest.find((harvest: any) => harvest.id === saleOrder.harvestDateId)
  }, [harvest, saleOrder.harvestDateId])

  const grainEntity = useMemo(() => {
    const findGrain = grainType.find((grain) => grain.id === saleOrder.grainId)
    if (findGrain) {
      return findGrain
    }
    return { grain: 'no disponible', code: '', id: -1, saggpya: -1, IDType: -1 }
  }, [grainType, saleOrder.grainId])

  const { origin, destination } = useMemo(() => {
    const origin = locationOrigin.find((location) => location.id == saleOrder.originId)
    const destination = locationDestination.find((destination) => destination.id == saleOrder.destinationId)
    return {
      origin: { location: origin?.title || 'no disponible' },
      destination: { destination: destination?.title || 'no disponible' }
    }
  }, [locationOrigin, locationDestination, saleOrder.destinationId, saleOrder.originId])

  const saleOrderComplete = {
    ...saleOrder,
    createdAt: new Date(),
    status: SaleOrderStatus.PENDING,
    Origin: origin,
    Destination: destination,
    HarvestDate: harvestEntity,
    Grain: grainEntity,
    Exchanger: {
      companyName: saleOrder.exchangerTaxId
    },
    Seller: {
      companyName: saleOrder.sellerTaxId
    }
  }

  const filteredRepresented = represented?.filter((rep) => rep.represented.taxId === saleOrder.sellerTaxId)
  const representationId = filteredRepresented && filteredRepresented.length ? filteredRepresented[0].id : null

  const dataToSend = {
    ...saleOrder,
    representationId
  }

  const onConfirmationPress = async () => {
    try {
      await dispatch(createSaleOrder(dataToSend))
      dispatch(showDrawerAction(Drawers.SALE_ORDER_SUCCESS))
    } catch (error) {
      dispatch(showDrawerAction(Drawers.SALE_ORDER_ERROR))
    } finally {
      dispatch(hideDrawer(Drawers.CONFIRM_NEW_SALE_ORDER))
    }
  }

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
      style={getDrawerStyles()}
    >
      <DrawerContainer>
        <HeaderContainer>
          <BackButton onClick={onClose}>
            <BackArrow />
          </BackButton>
          <DrawerTitle>Confirmá orden de venta</DrawerTitle>
        </HeaderContainer>
        <ContentContainer>
          <ContractDetailList saleOrder={saleOrderComplete} />
        </ContentContainer>
        <ButtonContainer>
          <ConfirmButton
            onPress={onConfirmationPress}
            loading={isCreatingSaleOrder}
          >
            <ButtonText testID="confirmOperation">
              Confirmar
            </ButtonText>
          </ConfirmButton>
        </ButtonContainer>
      </DrawerContainer>
    </Drawer>
  )
}

export default wrapper(ConfirmNewSaleOrder, Drawers.CONFIRM_NEW_SALE_ORDER)
