import { RepresentationActionTypes, Represented, RepresentedFormatted, IsRepresentative, Representative } from './types'

export const getRepresentedPending = () => ({
  type: RepresentationActionTypes.GET_REPRESENTED_PENDING
})

export const getRepresentedFulfilled = (represented: Represented[]) => ({
  type: RepresentationActionTypes.GET_REPRESENTED_FULFILLED,
  payload: represented
})

export const getRepresentedRejected = (error: Error) => ({
  type: RepresentationActionTypes.GET_REPRESENTED_REJECTED,
  payload: error
})

export const setRepresentedSelected = (values: RepresentedFormatted) => ({
  type: RepresentationActionTypes.SET_REPRESENTED_SELECTED,
  payload: values
})

export const getIsRepresentativePending = () => ({
  type: RepresentationActionTypes.GET_IS_REPRESENTATIVE_PENDING
})

export const getIsRepresentativeFulfilled = (isRepresentative: IsRepresentative) => ({
  type: RepresentationActionTypes.GET_IS_REPRESENTATIVE_FULFILLED,
  payload: isRepresentative
})

export const getIsRepresentativeRejected = (error: Error) => ({
  type: RepresentationActionTypes.GET_IS_REPRESENTATIVE_REJECTED,
  payload: error
})

export const getRepresentativePending = () => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_PENDING
})

export const getRepresentativeFulfilled = (representative: Representative) => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_FULFILLED,
  payload: representative
})

export const getRepresentativeRejected = (error: Error) => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_REJECTED,
  payload: error
})

export const getRepresentativeCompanyDataPending = () => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_PENDING
})

export const getRepresentativeCompanyDataFulfilled = (data: Representative) => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_FULFILLED,
  payload: data
})

export const getRepresentativeCompanyDataRejected = (error: Error) => ({
  type: RepresentationActionTypes.GET_REPRESENTATIVE_COMPANY_DATA_REJECTED,
  payload: error
})

export const clearRepresentativeCompanyDataRejected = () => ({
  type: RepresentationActionTypes.CLEAR_REPRESENTATIVE_COMPANY_DATA_REJECTED
})

export const setRepresentativePending = () => ({
  type: RepresentationActionTypes.SET_REPRESENTATIVE_PENDING
})

export const setRepresentativeFulfilled = (data: Representative) => ({
  type: RepresentationActionTypes.SET_REPRESENTATIVE_FULFILLED,
  payload: data
})

export const setRepresentativeRejected = (error: Error) => ({
  type: RepresentationActionTypes.SET_REPRESENTATIVE_REJECTED,
  payload: error
})

export const deleteRepresentativePending = () => ({
  type: RepresentationActionTypes.DELETE_REPRESENTATIVE_PENDING
})

export const deleteRepresentativeFulfilled = () => ({
  type: RepresentationActionTypes.DELETE_REPRESENTATIVE_FULFILLED
})

export const deleteRepresentativeRejected = (error: Error) => ({
  type: RepresentationActionTypes.DELETE_REPRESENTATIVE_REJECTED,
  payload: error
})
