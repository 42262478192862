import { ListItemType } from 'constants/common'

export const MENU_ITEMS = {
  home: 0,
  grains: 1,
  myAccount: 2,
  myData: 3,
  documentation: 4,
  redeemers: 5,
  customerSupport: 6,
  myRepresentatives: 7,
  myCompanies: 8,
  logout: 9,
  fraud: 10
}

export const MENU_SIDE_BAR = [
  {
    id: MENU_ITEMS.home,
    title: 'Inicio',
    icon: ListItemType.WALLET,
    to: '/content/wallet'
  },
  {
    id: MENU_ITEMS.grains,
    title: 'Granos',
    icon: ListItemType.GRAIN,
    to: '/content/grains',
    isCollapsable: true
  },
  {
    id: MENU_ITEMS.myAccount,
    title: 'Tu cuenta',
    icon: ListItemType.ACCOUNT,
    to: '/content/your-account'
  },
  {
    id: MENU_ITEMS.myData,
    title: 'Mis datos',
    icon: ListItemType.MY_DATA,
    to: '/content/my-data'
  },
  {
    id: MENU_ITEMS.documentation,
    title: 'Documentación',
    icon: ListItemType.DOCUMENTATION,
    to: '/content/documentation'
  },
  {
    id: MENU_ITEMS.redeemers,
    title: 'Canjeadores',
    icon: ListItemType.AGENTS,
    to: '/content/redeemers'
  },
  {
    id: MENU_ITEMS.customerSupport,
    title: 'Atención al cliente',
    icon: ListItemType.CUSTOMER_SUPPORT,
    to: '/content/customer-support'
  },
  {
    id: MENU_ITEMS.myRepresentatives,
    title: 'Mi representante',
    icon: ListItemType.YOUR_SELLER,
    to: '/content/representation'
  },
  {
    id: MENU_ITEMS.myCompanies,
    title: 'Mis empresas',
    icon: ListItemType.YOUR_ENTERPRISES,
    to: '/content/your-enterprises'
  },
  {
    id: MENU_ITEMS.fraud,
    title: 'Bloquear cuenta',
    icon: ListItemType.WARNING,
    to: '/content/block-account'
  },
  {
    id: MENU_ITEMS.logout,
    title: 'Cerrar sesión',
    icon: ListItemType.LOG_OUT,
    to: '/auth/login'
  }
]
