import React, { useEffect, useState } from 'react'
import { FlatList, Linking, View } from 'react-native'
import { useForm } from 'react-hook-form'
import Field from '@qirapagos/lib/components/molecules/Field/index.web'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'hooks/redux'
import { AgentModal, AvatarOutline } from 'components'
import { getInitials } from '@qirapagos/lib/utils/common'
import { Text } from '@chakra-ui/react'
import ButtonLine from '@qirapagos/lib/components/atoms/ButtonLine'
import YourSellerItemWeb from 'assets/svg/YourSellerItemWeb'
import { purple } from '@qirapagos/lib/theme/colors'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import { yupResolver } from '@hookform/resolvers/yup'
import { agentSchemeValidation } from 'validations/operationsForms'
import {
  ViewContainer,
  TextTitle,
  TextDescription,
  ViewFieldContainer,
  ViewInfoContainer,
  TextInfo,
  TextCompanyName,
  ViewButtonLine
} from './styleWeb'
import {
  ViewIcon,
  ViewAvatar,
  ViewInfoEach,
  style,
  ButtonTermsContainer
} from './styles'
import RepresentationSkeleton from 'assets/skeletons/RepresentationSkeleton'
import { getRepresentative, getRepresentativeCompanyData } from 'store/representation/thunks'
import { RootState } from 'store'

const Agents = () => {
  const dispatch = useDispatch()
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false)
  const { representative, isLoading, isLoadingRepresentative } = useSelector(
    (state: RootState) => state.representation
  )
  const { showDrawerMyAgent } = useAppSelector(state => state.modal)

  const { control, watch, errors, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(agentSchemeValidation)
  })
  const cuit = watch('cuit')
  const errorMessage = { invalidCuit: 'CUIT no valido' }

  const onPressAddAgent = (cuit: string) => {
    dispatch(getRepresentativeCompanyData(cuit, setError))
  }

  useEffect(() => {
    dispatch(getRepresentative())
  }, [dispatch])

  const onPressDeleteAgent = () => {
    setIsLogoutModalOpen(true)
  }

  const renderItem = ({ item }: any) => {
    return (
      <ViewInfoContainer>
        <ViewInfoEach>
          <Text
            fontWeight={500}
            color={purple}
            fontSize={{ sm: '19px', md: '20px', lg: '20px' }}
          >
            Alias
          </Text>
          <TextInfo>{item.alias}</TextInfo>
        </ViewInfoEach>
        <ViewInfoEach>
          <Text
            fontWeight={500}
            color={purple}
            fontSize={{ sm: '19px', md: '20px', lg: '20px' }}
          >
            CVU
          </Text>
          <TextInfo>{item.cvu}</TextInfo>
        </ViewInfoEach>
        <ViewInfoEach>
          <Text
            fontWeight={500}
            color={purple}
            fontSize={{ sm: '19px', md: '20px', lg: '20px' }}
          >
            CUIT
          </Text>
          <TextInfo>{item.taxId}</TextInfo>
        </ViewInfoEach>
        <ViewInfoEach>
          <Text
            fontWeight={500}
            color={purple}
            fontSize={{ sm: '19px', md: '20px', lg: '20px' }}
          >
            Banco
          </Text>
          <TextInfo>{item.bank}</TextInfo>
        </ViewInfoEach>
      </ViewInfoContainer>
    )
  }

  if (isLoadingRepresentative) {
    return (<RepresentationSkeleton />)
  }

  if (!representative) {
    return (
      <ViewContainer>
        <Helmet title={SCREEN_TITLES.MY_AGENT} />
        <ViewIcon>
          <YourSellerItemWeb />
        </ViewIcon>
        <TextTitle>Agregar un representante</TextTitle>
        <TextDescription>
          Tu representante podrá realizar contratos en tu nombre. Podrás
          visualizar esas órdenes de venta, pero no editarlas ni gestionarlas
          hasta que estén finalizadas.
        </TextDescription>
        <ViewFieldContainer>
          <Field
            control={control}
            name="cuit"
            label="CUIT/CUIL"
            error={errors.cuit}
            type="number-pad"
            autoFocus
            errorMessage={errorMessage}
            accessibilityLabelValue="CUIT / CUIL"
            customStyleContainer={{ marginTop: 50 }}
          />
        </ViewFieldContainer>

        <ButtonTermsContainer>
          <ButtonLine
            customStyle={style.termAndCon}
            onPress={() => Linking.openURL('https://www.qirapagos.com/terminos-y-condiciones')}
          >
            Términos y condiciones
          </ButtonLine>
        </ButtonTermsContainer>
        <ViewFieldContainer>
          <Button
            customStyle={style.button}
            disabled={
              !cuit || cuit.length < 11 || isLoading || showDrawerMyAgent
            }
            onPress={() => onPressAddAgent(cuit)}
            loading={isLoading}
          >
            Agregar representante
          </Button>
        </ViewFieldContainer>
      </ViewContainer>
    )
  }

  return (
    <ViewContainer>
      <Helmet title={SCREEN_TITLES.MY_AGENT} />
      <AgentModal
        isOpen={isLogoutModalOpen}
        setIsOpen={setIsLogoutModalOpen}
      />
      <ViewContainer>
        <ViewButtonLine>
          <ButtonLine onPress={() => onPressDeleteAgent()}>
            Eliminar representante
          </ButtonLine>
        </ViewButtonLine>
        <ViewAvatar>
          <AvatarOutline
            isBig
            initials={getInitials(representative.companyName) || ''}
          />
        </ViewAvatar>
        <TextCompanyName>{representative.companyName}</TextCompanyName>
        <View style={{ width: '100%' }}>
          <FlatList data={[representative]} renderItem={renderItem} />
        </View>
      </ViewContainer>
    </ViewContainer>
  )
}

export default Agents
