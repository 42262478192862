export enum SaleOrderStatus {
  PENDING = 'PENDIENTE',
  PENDING_PLUS = 'PENDIENTE+',
  REJECTED = 'RECHAZADA',
  REMOVED = 'ELIMINADA',
  COUNTER_OFFER = 'CONTRA_OFERTA',
  IN_PROCESS = 'EN_PROCESO',
  ACCEPTED = 'ACEPTADA',
  EXPORTABLE = 'EXPORTABLE',
  MIGRATED = 'MIGRADA'
}

export enum AppOperation {
  SALE_FIXED = 'PRECIO_HECHO',
  SALE_TO_FIX = 'PRECIO_A_FIJAR',
  SWAP_FIXED = 'CANJE_HECHO',
  SWAP_TO_FIX = 'CANJE_A_FIJAR'
}

export enum Currency {
  USD = 3,
  ARG = 2
}

export enum DeliveryPeriod {
  IMMEDIATE = 'INMEDIATA',
  CONTRACTUAL = 'CONTRACTUAL',
  FUTURE = 'FUTURA'
}

export enum FixationCondition {
  SLATE_PRICE = 'PRECIO_PIZARRA',
  MARKET_PRICE = 'PRECIO_MERCADO'
}

export const conditionsType = {
  [FixationCondition.SLATE_PRICE]: 'Precio Pizarra',
  [FixationCondition.MARKET_PRICE]: 'Precio Mercado'
}

export const appOperationText = {
  [AppOperation.SALE_FIXED]: 'Orden de venta con precio hecho',
  [AppOperation.SALE_TO_FIX]: 'Orden de venta con precio a fijar',
  [AppOperation.SWAP_FIXED]: 'Orden de canje con precio hecho',
  [AppOperation.SWAP_TO_FIX]: 'Orden de canje con precio a fijar'
}

export interface IHarvestDate {
  id: number
  startDate: number
  endDate: number
  isActive: boolean
}

export interface IGrain {
  grain: string
  code: string
  id: number
  saggpya: number
  IDType: number
}

export interface IApplication {
  id: number
  tableTempId: number | null
  agreement: string | null
  sellerClient: string | null
  buyerClient: string | null
  transporter: string | null
  recipient: string | null
  tempDownloadDate: string | null
  ctgNumber: string | null
  tempRomaneo: string | null
  tempCarPorte: string | null
  tempCarPorteDate: string | null
  originPlaceCode: string | null
  originPlaceDesc: string | null
  destinationPlaceCode: string | null
  destinationPlaceDesc: string | null
  tempAppliedKgs: number | null
  tempGrossDownloadKgs: number | null
  tempTareDownloadKgs: number | null
  tempNetDownloadKgs: number | null
  tempMermaDownloadKgs: number | null
  tempPendingApplyKgs: number | null
  grainQuality: string | null
  downloadState: string | null
  applicationState: string | null
  definitiveCtgConfirmation: string | null
  processed: number | null
  timestamp: Date | null
}

export interface ILiquidation {
  id: number
  agreement: string
  receipt: string | null
  description: string | null
  buyerClient: string | null
  sellerClient: string | null
  liquidatedKg: number | null
  partiallyLiquidatedKg: number | null
  price: number | null
  currency: string | null
  taxedAmount: number | null
  untaxedAmount: number | null
  VATAmount: number | null
  perceptionAmount: number | null
  retentionAmount: number | null
  grossIncomeRetentionAmount: number | null
  netAmount: number | null
  partiallyTaxedAmount: number | null
  partiallyUntaxedAmount: number | null
  partiallyVATAmount: number | null
  partiallyPerceptionAmount: number | null
  partiallyRetentionAmount: number | null
  partiallyGrossIncomeRetentionAmount: number | null
  partiallyNetAmount: number | null
  status: string | null
  date: string | null
  processed: number | null
  timestamp: Date | null
}

export interface SaleOrder {
  appOperation: AppOperation
  sellerTaxId: string
  conditionDateFrom?: string
  conditionDateTo?: string
  currencyId?: Currency
  deliveryFrom: string
  deliveryPeriod: DeliveryPeriod
  deliveryTo: string
  destinationId: number
  exchangeAmount?: number
  exchangeCurrencyId?: Currency
  exchangeProduct?: string
  exchangerTaxId?: string
  fixationCondition?: FixationCondition
  grainId: number
  harvestDateId: number
  HarvestDate?: IHarvestDate
  originId: number
  price: number
  kg: number
  agreement?: number
  tons?: number
  representationId: number | null
}
export interface Quota {
  status?: number | string
  deliveryDate: Date
  weight: number
  id: number
}

export interface QuotaComplete extends Quota {
  quotaCode: string | null
  traceabilityCode: string | null
  portTerminal: string | null
  observation: string | null
  timestamp: Date
  saleOrderId: number
  marketId: number | null
  intermediaryId: number | null
  remitterId: number | null
  brokerSellerId: number | null
  brokerBuyerId: number | null
  locationId: number | null
  delivererId: number | null
  receiverId: number | null
  transporterId: number | null
  Intermediary?: {
    companyName: string | null
  }
  Remitter?: {
    companyName: string | null
  }
  BrokerSeller?: {
    companyName: string | null
  }
  BrokerBuyer?: {
    companyName: string | null
  }
  Location?: {
    location: string | null
  }
  Deliverer?: {
    deliverer: string | null
  }
  Receiver?: {
    receiver: string | null
  }
  Transport?: {
    transport: string | null
  }
}

export interface Representative {
  companyName: string;
}

export interface Represented {
  companyName: string;
}
export interface Representation {
  id: number;
  active: boolean;
  companyId: number;
  representative: Representative;
  representativeId: number;
  represented: Represented;
  timestamp: Date;
}

export interface SaleOrderCreated extends SaleOrder {
  id: number
  createdAt: Date
  Grain: IGrain
  status: SaleOrderStatus
  Exchanger: {
    companyName?: string
  }
  Seller: {
    companyName: string
  }
  Destination: {
    location: string
  }
  Origin: {
    location: string
  }
  Quota?: Quota[]
  applications?: IApplication[]
  liquidations?: ILiquidation[]
  representationId: number
  Representation?: Representation
}

export interface ContractState {
  isLoading: boolean
  saleOrders: SaleOrderCreated[]
  error?: undefined
  selected?: SaleOrderCreated
  quotaSelected?: QuotaComplete
  isQuotaLoading?: boolean
  selectedApplication?: IApplication
  selectedLiquidation?: ILiquidation
}

export interface QuotaDate {
  deliveryDate: string
}
export interface QuotaBody {
  body: QuotaDate[]
}

export enum ContractActionTypes {
  GET_SALE_ORDERS_FULFILLED = '@CONTRACT:GET_SALE_ORDERS_FULFILLED',
  GET_SALE_ORDERS_PENDING = '@CONTRACT:GET_SALE_ORDERS_PENDING',
  GET_SALE_ORDERS_REJECTED = '@CONTRACT:GET_SALE_ORDERS_REJECTED',
  SELECT_SALE_ORDERS = '@CONTRACT:SELECT_SALE_ORDERS',
  CREATE_SALE_ORDER_FULFILLED = '@CONTRACT:CREATE_SALE_ORDER_FULFILLED',
  CREATE_SALE_ORDER_PENDING = '@CONTRACT:CREATE_SALE_ORDER_PENDING',
  CREATE_SALE_ORDER_REJECTED = '@CONTRACT:CREATE_SALE_ORDER_REJECTED',
  REPLY_COUNTER_OFFER_FULFILLED = '@CONTRACT:REPLY_COUNTER_OFFER_FULFILLED',
  REPLY_COUNTER_OFFER_PENDING = '@CONTRACT:REPLY_COUNTER_OFFER_PENDING',
  REPLY_COUNTER_OFFER_REJECTED = '@CONTRACT:REPLY_COUNTER_OFFER_REJECTED',
  GET_SALE_ORDER_FULFILLED = '@CONTRACT:GET_SALE_ORDER_FULFILLED',
  GET_SALE_ORDER_PENDING = '@CONTRACT:GET_SALE_ORDER_PENDING',
  GET_SALE_ORDER_REJECTED = '@CONTRACT:GET_SALE_ORDER_REJECTED',
  CREATE_QUOTAS_FULFILLED = '@CONTRACT:CREATE_QUOTAS_FULFILLED',
  CREATE_QUOTAS_PENDING = '@CONTRACT:CREATE_QUOTAS_PENDING',
  CREATE_QUOTAS_REJECTED = '@CONTRACT:CREATE_QUOTAS_REJECTED',
  GET_QUOTA_FULFILLED = '@CONTRACT:GET_QUOTA_FULFILLED',
  GET_QUOTA_PENDING = '@CONTRACT:GET_QUOTA_PENDING',
  GET_QUOTA_REJECTED = '@CONTRACT:GET_QUOTA_REJECTED',
  SELECT_APPLICATION = '@CONTRACT:SELECT_APPLICATION',
  SELECT_LIQUIDATION = '@CONTRACT:SELECT_LIQUIDATION'
}
