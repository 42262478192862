import { Action } from '../../interfaces/reduxInterfaces'
import initialState from './initialState'
import { MenuActionTypes, MenuState } from './types'

export default function menuReducer (state = initialState, action: Action): MenuState {
  switch (action.type) {
    case MenuActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case MenuActionTypes.DISMISS_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case MenuActionTypes.SET_VENDOR:
      return {
        ...state,
        vendor: action.payload
      }
    case MenuActionTypes.SET_TYPE_PAYMENT_METHOD:
      return {
        ...state,
        typepaymentmethod: action.payload
      }
    case MenuActionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentmethod: action.payload
      }
    case MenuActionTypes.SET_REDEEMERS:
      return {
        ...state,
        redeemers: state.redeemers?.concat(action.payload)
      }
    case MenuActionTypes.SET_COUNT_PAGE_REDEEMERS:
      return {
        ...state,
        countPageRedeemers: action.payload
      }
    case MenuActionTypes.SET_CLEAR_REDEEMERS:
      return {
        ...state,
        redeemers: initialState.redeemers
      }
    case MenuActionTypes.LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
