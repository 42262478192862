import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonLine } from 'components'
import Button from 'components/atoms/Button'
import CustomText from 'components/atoms/CustomText'
import { Container, Tons, HowWorksContainer } from './styles'
import { SaleOrderCreated } from 'store/contracts/types'
import { useDispatch, useSelector } from 'react-redux'
import { showDrawer as showDrawerAction } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { useAppSelector } from 'hooks/redux'
import { getSaleOrder } from 'store/contracts/thunks'
import { RootState } from 'store'
import { getIsRepresentative } from 'store/representation/thunks'
interface Props {
  saleOrder: SaleOrderCreated
}

export enum StatusQuota {
  REQUIRED = 1,
  AWARDED = 2,
  REJECTED = 3,
  ANNULLED = 4
}

const QuoteRequest: React.FC<Props> = () => {
  const { selected } = useAppSelector(state => state.contractsV2)
  const dispatch = useDispatch()
  const { saleOrderId } = useParams()
  const tons = selected?.tons ? selected?.tons : 0
  const { isRepresentative } = useSelector((state: RootState) => state.representation)

  useEffect(() => {
    dispatch(getIsRepresentative())
  }, [])

  useEffect(() => {
    if (!selected || selected.id !== Number(saleOrderId)) {
      dispatch(getSaleOrder(Number(saleOrderId)))
    }
  }, [dispatch, saleOrderId, selected, selected?.Quota])

  const navigate = useNavigate()
  const onPressHowWorksQuotas = () => {
    dispatch(showDrawerAction(Drawers.HOW_WORKS_QUOTA))
  }

  const arrayPreviousQuotas = selected?.Quota?.filter(quota =>
    quota.status === StatusQuota.AWARDED ||
    quota.status === StatusQuota.REQUIRED
  )

  const previousQuotas = arrayPreviousQuotas?.length ? arrayPreviousQuotas.length : 0
  const accumulatedQuota = previousQuotas * 30

  const totalTonsToRequest = tons
  const tonsAvailableToRequest =
    tons - accumulatedQuota <= 0 ? tons : accumulatedQuota

  if ((selected?.representationId && selected.Representation?.active && !isRepresentative) ||
    !selected?.representationId) {
    return (
      <Container>
        <HowWorksContainer>
          <ButtonLine
            onPress={onPressHowWorksQuotas}
            customStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
            fontSize="medium"
          >
            ¿Cómo funciona?
          </ButtonLine>
        </HowWorksContainer>
        <CustomText weight="semibold" size="mmbig">
          Cupos solicitados
        </CustomText>
        <Tons>
          <CustomText size="large">
            {tonsAvailableToRequest}
            tt /
          </CustomText>
          <CustomText weight="semibold" size="large" color="gray">
            {totalTonsToRequest}
            tt
          </CustomText>
        </Tons>
        <Button
          onPress={() => navigate(`/content/grains/quotas-set/${saleOrderId}`)}
          disabled={tonsAvailableToRequest === tons}
        >
          Solicitar nuevo cupo
        </Button>
      </Container>
    )
  } else {
    return (
      <Container>
        <CustomText weight="semibold" size="mmbig">
          No puedes solicitar cupos
        </CustomText>
      </Container>
    )
  }
}

export default QuoteRequest
