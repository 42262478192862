export interface Represented {
  id: number,
  companyId: number,
  representativeId: number,
  active: boolean,
  represented: {
    taxId: string,
    companyName: string,
    phone: string,
    email: string,
    Vendor: {
        name: string,
    },
    CompanyStatus: {
        status: string,
    }
  }
}
export interface RepresentedFormatted {
  id: number
  companyName: string,
  taxId: string,
  phone: string,
  email: string,
  vendor: string,
  status: string,
}

export interface Representative {
  relationshipId: number,
  companyId: number,
  taxId: string,
  companyName: string,
  address: string,
  addressNumber: string,
  email: string,
  phone:string,
  cvu: string,
  alias: string,
  bank: string,
  cuit?: string
}

export interface RepresentationState {
  represented?: Represented[];
  isLoading: boolean,
  isLoadingRepresentative: boolean,
  isDeletingRepresentative: boolean,
  isRepresentativeLoading: boolean,
  error: string | undefined,
  selectedRepresented?: RepresentedFormatted
  isRepresentative: boolean
  representative?: Representative;
  dataRepresentativeProvisory?: Representative;
}

export enum RepresentationActionTypes {
  GET_REPRESENTED_PENDING = '@REPRESENTATION:GET_REPRESENTED_PENDING',
  GET_REPRESENTED_FULFILLED = '@REPRESENTATION:GET_REPRESENTED_FULFILLED',
  GET_REPRESENTED_REJECTED = '@REPRESENTATION:GET_REPRESENTED_REJECTED',
  SET_REPRESENTED_SELECTED = '@REPRESENTATION:SET_REPRESENTED_SELECTED',
  GET_IS_REPRESENTATIVE_PENDING = '@REPRESENTATION:GET_IS_REPRESENTATIVE_PENDING',
  GET_IS_REPRESENTATIVE_FULFILLED = '@REPRESENTATION:GET_IS_REPRESENTATIVE_FULFILLED',
  GET_IS_REPRESENTATIVE_REJECTED= '@REPRESENTATION:GET_IS_REPRESENTATIVE_REJECTED',
  GET_REPRESENTATIVE_PENDING = '@REPRESENTATION:GET_REPRESENTATIVE_PENDING',
  GET_REPRESENTATIVE_FULFILLED = '@REPRESENTATION:GET_REPRESENTATIVE_FULFILLED',
  GET_REPRESENTATIVE_REJECTED= '@REPRESENTATION:GET_REPRESENTATIVE_REJECTED',
  GET_REPRESENTATIVE_COMPANY_DATA_PENDING = '@REPRESENTATION:GET_REPRESENTATIVE_COMPANY_DATA_PENDING',
  GET_REPRESENTATIVE_COMPANY_DATA_FULFILLED = '@REPRESENTATION:GET_REPRESENTATIVE_COMPANY_DATA_FULFILLED',
  GET_REPRESENTATIVE_COMPANY_DATA_REJECTED= '@REPRESENTATION:GET_REPRESENTATIVE_COMPANY_DATA_REJECTED',
  SET_REPRESENTATIVE_PENDING = '@REPRESENTATION:SET_REPRESENTATIVE_PENDING',
  SET_REPRESENTATIVE_FULFILLED = '@REPRESENTATION:SET_REPRESENTATIVE_FULFILLED',
  SET_REPRESENTATIVE_REJECTED= '@REPRESENTATION:SET_REPRESENTATIVE_REJECTED',
  DELETE_REPRESENTATIVE_PENDING = '@REPRESENTATION:DELETE_REPRESENTATIVE_PENDING',
  DELETE_REPRESENTATIVE_FULFILLED = '@REPRESENTATION:DELETE_REPRESENTATIVE_FULFILLED',
  DELETE_REPRESENTATIVE_REJECTED = '@REPRESENTATION:DELETE_REPRESENTATIVE_REJECTED',
  CLEAR_REPRESENTATIVE_COMPANY_DATA_REJECTED = '@CLEAR_REPRESENTATIVE_COMPANY_DATA_REJECTED'
}

export interface IsRepresentative {
  isRepresentative: boolean;
}
